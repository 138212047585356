import React, { useState } from "react";
import { MockOrdersData, mockSellers, utils } from "../../components/utils";
import { toast, ToastContainer } from 'react-toastify';
import TableComponent from "../../components/TableComponent";
import { Column, DataRow } from "../../hooks/useUtilities";
import axios from "axios";
import { baseUrl } from "../../webbase";
import cookie from "react-cookies"
import { useParams } from "react-router-dom";
import { FaUser } from "react-icons/fa";
import { ScaleLoader } from "react-spinners";

const SingleProduct = () => {
  const [product, setProduct] = useState({
    images: []
  });
  const { productId } = useParams();
  const [isLoading, setIsLoading] = useState(false)
  console.log("🚀 ~ file: SingleProduct.jsx:14 ~ SingleProduct ~ productId:", productId)

  React.useEffect(() => {
    const token = cookie.load("Jekinraa-admin");
    setIsLoading(true)
    axios
      .get(baseUrl + "/adminapi/getProduct/" + productId, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(({ data }) => {
        setProduct(data.data)
        console.log(data.data)
      })
      .catch((e) => {
        console.log(e);
      }).finally(() => {
        setIsLoading(false)
      });
  }, []);

  //Approve Product
  const handleApproveProduct = (id: any) => {
    setIsLoading(true)
    const token = cookie.load("Jekinraa-admin");
    axios
      .get(baseUrl + `/adminapi/approveProduct/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log(res.data.message);
        toast.success(res.data.message);
        if (res.data.code == 200) {
          window.location.reload();
        }
      })
      .catch((e) => {
        console.log(e);
      }).finally(() => {
        setIsLoading(false)
      });
  };

  // Handle Disapprove Product
  const handleDisapproveProduct = (id: any) => {
    setIsLoading(true)
    const token = cookie.load("Jekinraa-admin");
    axios
      .get(baseUrl + `/adminapi/disapproveProduct/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log(res.data.message);
        toast.success(res.data.message);
        if (res.data.code == 200) {
          window.location.reload();
        }
      })
      .catch((e) => {
        console.log(e);
      }).finally(() => {
        setIsLoading(false)
      });;
  };


  return (
    <div className="p-4 bg-white">
      <ToastContainer />
      {isLoading ? (
        <>
          <div className="items-center justify-center">
            <ScaleLoader
              color="#9D00AF"
              height={20}
              className="self-center justify-self-center"
            />
          </div>
        </>
      ) : (
        <div className="w-full md:w-11/12 flex flex-col md:space-y-3 space-y-1 mt-4">
          <div className="flex flex-row flex-wrap space-x-3">
            <div className="flex flex-col space-y-3 w-3/6 border-stroke border rounded-md hidden md:block  max-h-fit p-6">
              <div className="w-full  rounded-lg  flex items-center">
                <img
                  alt={product.name}
                  src={product.images[0]?.url}
                  style={{
                    flex: 1,
                    alignSelf: "center",
                    minWidth: 120,
                    height: "100%",
                    display: "block",
                    objectFit: "cover",
                    backgroundColor: "#E0E0E050",
                  }}
                />
              </div>
            </div>
            <div className="flex flex-col space-y-3 flex-1 w-3/6 p-4 hidden md:block ">
              <div className="flex flex-row justify-start space-x-6 items-center">
                <div className={"font-bold text-2xl"}>{product.name}</div>

              </div>
              <div
                id={"productDescription"}
                className={"font-light text-md text-gray-400"}
              >
                {
                  product?.description
                }
              </div>
              <div className="flex flex-row justify-start space-x-3 items-center">
                <div className=" w-6 h-6 bg-white rounded-full flex items-center justify-center border rounded-full border-gray-400">
                  <FaUser className={" text-gray-400 "} width={16} />
                </div>
                <div className={"font-normal text-md"}>
                  {product?.sellerName}
                </div>
              </div>
              <div className="flex flex-row justify-start space-x-3 items-center">
                <div className={"font-bold text-2xl"}>
                  {" "}
                  ₦{new Number(product?.price).toLocaleString("en-US")}
                </div>
              </div>
              <div className="flex flex-row justify-start space-x-3 items-center">
                <button
                  className="bg-yellow-500 text-white font-semibold p-2 rounded-md "
                  onClick={() => handleApproveProduct(product?.id)}
                >
                  Approve Product
                </button>
                <button
                  className="bg-black text-white font-semibold p-2   rounded-md "
                  onClick={() => handleDisapproveProduct(product?.id)}
                >
                  Disapprove Product
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SingleProduct;
