import axios from "axios";
import React, { useState } from "react";
import { baseUrl } from "../webbase";
import { toast } from "react-hot-toast";
import { useNavigate, useNavigation } from "react-router-dom";

import cookie from "react-cookies"

const AdminContext = React.createContext({
  admin: null,
});
const AdminProvider = ({ children }) => {
  const [admin, setAdmin] = React.useState();
  const navigate = useNavigate();
  const token = cookie.load("Jekinraa-admin");
  console.log("🚀 ~ file: AdminProvider.js:17 ~ AdminProvider ~ token:", token)
  React.useEffect(() => {
    (() => {
      if (token === "" || !token) {
        toast("It seems you are not logged in", {
          className: "bg-[#9D00AF] text-white",
          duration: 3000,
          position: "top-right",
        });
        navigate("/login");
      } else {
        axios
          .get(`${baseUrl}/adminapi/details `, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((res) => {
            const { data } = res.data;


            if (data) {
              setAdmin(data);
            }
          });
      }
    })();
  }, []);

  return (
    <AdminContext.Provider value={{ admin, setAdmin, token }}>
      {children}
    </AdminContext.Provider>
  );
};

export default AdminProvider;
export const AdminState = () => React.useContext(AdminContext);
