import React from "react";

// Define a functional component for the table header
export default function TableHeader(props) {
    // Destructure the props object to get the column names
    const [columns] = React.useState([" Order ID", "User", "Product name", "Address", "quantity","amount", "price","status"]);

    // Return the JSX elements for the table header
    return (
        <thead className="shadow-sm">
            <tr>
                {/* Loop through the column names and render each table header cell */}
                {columns.map((column, idx) => (
                    <th
                        key={idx}
                        scope="col"
                        className="px-5 py-3 bg-white border-b border-gray-200 text-gray-800 text-left text-sm uppercase font-normal"
                    >
                        <div className="flex items-center space-x-2">

                            {idx === 0 &&
                                <input type="checkbox" className="w-[15px] h-[15px]" name="" id="" />
                            }
                            <span>

                                {column}
                            </span>
                        </div>
                    </th>
                ))}
            </tr>
        </thead>
    );
}
