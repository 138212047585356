import React, {useState, useEffect, MouseEvent} from "react";
import axios from "axios";
import {toast} from "react-toastify";
import {baseUrl} from "../webbase";
import {Column, DataRow, TableComponentProps} from "../hooks/useUtilities";
import SearchBox from "../components/SearchBox";
import TableHeader from "../components/TableHeader";
import {Plus} from "react-bootstrap-icons";
import {LuArrowDownUp} from "react-icons/lu";
import {statusColors, utils} from "./utils";
import {ChangeStatusComponent} from "./changeStatus";
import {
  handleApproveProduct,
  handleDisapproveSeller,
  handleDisapproveProduct,
  handleApproveSeller,
} from "../utils";
import cookie from "react-cookies";
import {ScaleLoader} from "react-spinners";
import {useNavigate, useNavigation} from "react-router";
const TableComponent: React.FC<TableComponentProps> = ({
  columns,
  data,
  onRowSelect,
  fetching,
}) => {
  const navigation = useNavigate();
  const [sortedAndFilteredData, setSortedAndFilteredData] = useState<DataRow[]>(
    []
  );
  const [selectedRows, setSelectedRows] = useState<Set<number>>(new Set());
  const [sortConfig, setSortConfig] = useState<{
    key: string;
    direction: "ascending" | "descending";
  } | null>(null);
  const [filter, setFilter] = useState<string>("");

  // Handle Disapprove Seller
  const handleDisapproveSeller = (id: any) => {
    const token = cookie.load("Jekinraa-admin");
    axios
      .get(baseUrl + `/adminapi/disapproveVendor/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log(res.data.message);
        toast.success(res.data.message);
        if (res.data.code == 200) {
          window.location.reload();
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
  // Handle Disapprove Product
  const handleDisapproveProduct = (id: any) => {
    const token = cookie.load("Jekinraa-admin");
    axios
      .get(baseUrl + `/adminapi/disapproveProduct/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log(res.data.message);
        toast.success(res.data.message);
        if (res.data.code == 200) {
          window.location.reload();
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  //Approve Seller
  const handleApproveSeller = (id: any) => {
    const token = cookie.load("Jekinraa-admin");
    axios
      .get(baseUrl + `/adminapi/approveVendor/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log(res.data.message);
        toast.success(res.data.message);
        if (res.data.code == 200) {
          window.location.reload();
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
  //Approve Product
  const handleApproveProduct = (id: any) => {
    const token = cookie.load("Jekinraa-admin");
    axios
      .get(baseUrl + `/adminapi/approveProduct/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log(res.data.message);
        toast.success(res.data.message);
        if (res.data.code == 200) {
          window.location.reload();
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  //Block Vendor
  const handleBlockUser = (id: any) => {
    const token = cookie.load("Jekinraa-admin");
    axios
      .get(baseUrl + `/adminapi/blockUser/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log(res.data.message);
        toast.success(res.data.message);
        if (res.data.code == 200) {
          window.location.reload();
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  //unblock Vendor
  const handleUnblockUser = (id: any) => {
    const token = cookie.load("Jekinraa-admin");
    axios
      .get(baseUrl + `/adminapi/unblockUser/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log(res.data.message);
        toast.success(res.data.message);
        if (res.data.code == 200) {
          window.location.reload();
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    let processedData = [...data];

    // Filtering logic
    if (filter) {
      processedData = processedData.filter((row) =>
        columns.some((column) =>
          row[column.accessor]
            .toString()
            .toLowerCase()
            .includes(filter.toLowerCase())
        )
      );
    }
    // Sorting logic
    if (sortConfig !== null) {
      processedData.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }

    setSortedAndFilteredData(processedData);
  }, [data, sortConfig, filter, columns]);

  const handleSort = (column: Column) => {
    if (!column.sortable) return;
    if (
      sortConfig &&
      sortConfig.key === column.accessor &&
      sortConfig.direction === "ascending"
    ) {
      setSortConfig({key: column.accessor, direction: "descending"});
    } else {
      setSortConfig({key: column.accessor, direction: "ascending"});
    }
  };

  const handleRowSelect = (rowId: number) => {
    const newSelectedRows = new Set(selectedRows);
    if (newSelectedRows.has(rowId)) {
      newSelectedRows.delete(rowId);
    } else {
      newSelectedRows.add(rowId);
    }
    setSelectedRows(newSelectedRows);
    onRowSelect(Array.from(newSelectedRows));
  };

  const renderTableHeaders = () => {
    return columns.map((column, index) => (
      <th key={index} onClick={() => handleSort(column)}>
        <div className="flex items-center space-x-2 py-2">{column.header}</div>
      </th>
    ));
  };

  const renderTableData = () => {
    return sortedAndFilteredData.map((row, index) => (
      <tr
        key={index}
        className="h-14 border-b border-gray-200 cursor-pointer hover:bg-gray-100"
        onClick={() => {
          if (row?.images) {
            navigation("/product/" + row?.id);
          }
        }}
      >
        <td className="px-4 whitespace-nowra">
          <input
            type="checkbox"
            checked={selectedRows.has(row.id)}
            onChange={() => handleRowSelect(row.id)}
          />
        </td>

        {columns.map((column, colIndex) => {
          let cellData = row[column.accessor];
          let statusColor;
          let sellerID: any;
          // console.log(cellData);

          if (typeof cellData === "string" && cellData.length > 18) {
            cellData = cellData.substring(0, 18) + "...";
          }
          if (column.accessor == "status") {
            if (cellData == "1") {
              cellData = "Approved";
              statusColor = "bg-yellow-500";

              return (
                <td className="px-2 text-[#1C1C1C]" key={colIndex}>
                  <div className="relative inline-block text-left">
                    <span
                      className={`inline-block h-3 w-3 rounded-full mr-2 ${statusColor}`}
                    ></span>
                    {cellData}
                  </div>
                </td>
              );
            } else if (cellData == "0") {
              cellData = "Pending";
              statusColor = "bg-yellow-500";
              sellerID = row.id;
              return (
                <td className="px-2 text-[#1C1C1C]" key={colIndex}>
                  <div className="relative inline-block text-left">
                    <span
                      className={`inline-block h-3 w-3 rounded-full mr-2 ${statusColor}`}
                    ></span>
                    {cellData}
                  </div>
                </td>
              );
            }
          }
          if (column.accessor == "images[0].url") {
            cellData = (
              <img
                src={row["images"][0]?.url}
                alt=""
                style={{
                  objectFit: "cover",
                  alignSelf: "center",
                  minWidth: 50,
                  maxHeight: 60,
                  height: "100%",
                  display: "block",
                }}
                className="rounded-full h-10 w-10"
              />
            );
          }
          if (column.accessor === "logo") {
            cellData = (
              <img
                src={row[column.accessor]}
                alt=""
                className="rounded-full h-10 w-10"
              />
            );
          }
          if (column.accessor === "request") {
            if (row.user_type === "2") {
              cellData = (
                <button
                  className="bg-black text-white font-semibold p-2   rounded-md "
                  onClick={() => handleDisapproveSeller(row.id)}
                >
                  Disapprove
                </button>
              );
            }
            if (row.user_type == "1") {
              cellData = (
                <button
                  className="bg-yellow-500 text-white font-semibold p-2 rounded-md "
                  onClick={() => handleApproveSeller(row.id)}
                >
                  Approve Seller
                </button>
              );
            }
          }
          if (column.accessor === "product-action") {
            if (row.status == "1") {
              cellData = (
                <button
                  className="bg-black text-white font-semibold p-2   rounded-md "
                  onClick={() => handleDisapproveProduct(row.id)}
                >
                  Disapprove Product
                </button>
              );
            }
            if (row.status == "0") {
              cellData = (
                <button
                  className="bg-yellow-500 text-white font-semibold p-2 rounded-md "
                  onClick={() => handleApproveProduct(row.id)}
                >
                  Approve Product
                </button>
              );
            }
          }
          if (column.accessor === "access") {
            if (row.status === 0) {
              cellData = (
                <button
                  className="bg-black text-white font-semibold p-2   rounded-md "
                  onClick={() => handleUnblockUser(row.id)}
                >
                  Unblock
                </button>
              );
            }
            if (row.status === 1) {
              cellData = (
                <button
                  className="bg-yellow-500 text-white font-semibold p-2 rounded-md "
                  onClick={() => handleBlockUser(row.id)}
                >
                  Block
                </button>
              );
            }
          }
          return (
            <td className="px-2 text-[#1C1C1C]" key={colIndex}>
              <div className="relative inline-block text-left">{cellData}</div>
            </td>
          );
        })}
      </tr>
    ));
  };

  return (
    <div className="">
      {fetching ? (
        <>
          <div className="items-center justify-center">
            <ScaleLoader
              color="#9D00AF"
              height={20}
              className="self-center justify-self-center"
            />
          </div>
        </>
      ) : (
        <>
          <div className="bg-gray-100 rounded-md my-[10px] flex justify-between items-center py-2 px-4">
            <div className="flex space-x-4">
              <div>
                <Plus />
              </div>

              <img
                className="cursor-pointer w-[15px] h-[15px] rotate-180"
                src={utils.tableHamburgerIcon}
                alt=""
              />

              <div>
                <LuArrowDownUp />
              </div>
            </div>
            <div className="relative height">
              <input
                type="text"
                className="bg-gray-100 border-2 border-gray-300 rounded-md py-2 px-4 focus:outline-none "
                placeholder="Search"
              />
              <span className="absolute right-4 top-3 text-gray-400">
                <svg
                  className="h-4 w-5 fill-current"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                >
                  <path d="M10 18a8 8 0 1 1 0-16 8 8 0 0 1 0 16zm5.707-13.293a1 1 0 0 0-1.414 0l-4.243 4.243a1 1 0 0 0 0 1.414l4.243 4.243a1 1 0 0 0 1.414-1.414L13.414 10l3.293-3.293a1 1 0 0 0 0-1.414z" />
                </svg>
              </span>
            </div>
          </div>
          <table className=" w-full">
            <thead className="shadow-sm">
              <tr className="px-5 py-3 bg-white border-b border-gray-200 text-gray-800 text-left text-sm uppercase font-normal">
                <th className="w-12"></th>
                {renderTableHeaders()}
              </tr>
            </thead>
            <tbody className="text-sm">{renderTableData()}</tbody>
          </table>
        </>
      )}
    </div>
  );
};

export default TableComponent;
