import { useState } from "react"
import { Boxes, CashStack, People } from "react-bootstrap-icons";

import { pieChartData, barChartData } from "../utils";
import SalesChart from "../PieChart";
import TransactionsChart from "../BarChart";

const ProjectsUI = () => {

   const [number, setNumber] = useState(0);
   const [stats, setStats] = useState(0);

   const projectItems = [
      {
         title: "Total Users",
         icon: <People />
      },
      {
         title: "Total Products",
         icon: <Boxes />,
         color: "secondary"
      },
      {
         title: "Total Transactions",
         icon: <CashStack />
      },
      {
         title: "Total Payouts",
         icon: <CashStack />,
         color: "secondary"
      },
   ]

   const styles = {
      roundedDiv: " rounded-2xl  px-4 py-7  flex justify-between items-center",
      unroundedDiv: "flex  flex-col gap-3 ",
      title: 'font-Kumbh text-[0.935rem] font-semibold',
      number: "font-Kumbh  text-3xl text-[rgba(0,0,0,.9)] font-medium",
      icon: "font-Kumbh  text-xl text-[rgba(0,0,0,.9)] font-normal",
      stats: "font-Kumbh  text-base text-[rgba(0,0,0,.9)] font-normal"
   }

   const { roundedDiv, unroundedDiv, title, number: Number, icon, stats: Stats } = styles

   return (
      <div
         className="py-5 "
      >
         {/* sales summary */}
         <div className="flex-col md:flex-row flex w-full flex-1 gap-4 flex-wrap">
            {

               projectItems.map((item) => {
                  if (item.color) {
                     return (
                        <div
                           className={"bg-[#E5ECF6] flex-1 md:w-[13rem] " + roundedDiv}>
                           <div className={unroundedDiv}>
                              <div
                                 className={title}
                              >
                                 {item.title}
                              </div>
                              <div
                                 className={Number}
                              >
                                 {number}
                              </div>
                           </div>
                           <div
                              className={unroundedDiv}
                           >
                              <div
                                 className={icon}
                              >
                                 {item.icon}
                              </div>
                              <div
                                 className={Stats}
                              >
                                 {stats}
                              </div>
                           </div>
                        </div>
                     )
                  }

                  return (
                     <div
                        className={"bg-[#cee0fe] flex-1 md:w-[13rem] " + roundedDiv}
                     >
                        <div
                           className={unroundedDiv}
                        >
                           <div
                              className={title}
                           >
                              {item.title}
                           </div>
                           <div
                              className={Number}
                           >
                              {number}
                           </div>
                        </div>
                        <div
                           className={unroundedDiv}
                        >
                           <div
                              className={icon}
                           >
                              {item.icon}
                           </div>
                           <div
                              className={Stats}
                           >
                              {stats}
                           </div>
                        </div>
                     </div>
                  )
               })
            }
         </div>
         {/* sales charts */}
         <div className="flex-col md:flex-row flex w-full flex-1 gap-4 mt-4 ">
            <div className={"bg-[#F7F9FB] md:w-1/4 sm:w-full" + roundedDiv}>
               <SalesChart data={pieChartData}  />
            </div>
            <div
               className={"bg-[#F7F9FB] w-3/4 " + roundedDiv}>
            </div>
         </div>
         <div className="flex-col md:flex-row flex w-full flex-1 gap-4 mt-4 ">
            <div
               className={"bg-[#F7F9FB] w-full " + roundedDiv}
            >
               <TransactionsChart data={barChartData} />
            </div>
         </div>
      </div >
   )
}

export default ProjectsUI