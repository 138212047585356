import {baseUrl} from "./webbase";
import axios from "axios";
import {toast} from "react-toastify";
import cookie from "react-cookies";

export const EXPIRY_DATE: any = 1000 * 60 * 60 * 24 * 3;

// Handle Disapprove Seller
export const handleDisapproveSeller = (id: any) => {
  const token = cookie.load("Jekinraa-admin");
  axios
    .get(baseUrl + `/adminapi/disapproveVendor/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      console.log(res.data.message);
      toast.success(res.data.message);
      window.location.reload();
    })
    .catch((e) => {
      console.log(e);
    });
};

// Handle Disapprove Product
export const handleDisapproveProduct = (id: any) => {
  const token = cookie.load("Jekinraa-admin");
  axios
    .get(baseUrl + `/adminapi/disapproveProduct/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      console.log(res.data.message);
      toast.success(res.data.message);
      window.location.reload();
    })
    .catch((e) => {
      console.log(e);
    });
};

//Approve Seller
export const handleApproveSeller = (id: any) => {
  const token = cookie.load("Jekinraa-admin");
  axios
    .get(baseUrl + `/adminapi/approveVendor/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      console.log(res.data.message);
      toast.success(res.data.message);
      window.location.reload();
    })
    .catch((e) => {
      console.log(e);
    });
};

//Approve Product
export const handleApproveProduct = (id: any) => {
  const token = cookie.load("Jekinraa-admin");
  axios
    .get(baseUrl + `/adminapi/approveProduct/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      console.log(res.data.message);
      toast.success(res.data.message);
      window.location.reload();
    })
    .catch((e) => {
      console.log(e);
    });
};

export const setCookieToken = (token: string) => {
  console.log("🚀 ~ file: utils.tsx:85 ~ setCookieToken ~ token:", token);
  return cookie.save("Jekinraa-admin", token, {
    path: "/",
  });
};
