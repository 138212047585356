import react, { useState, useEffect } from "react";
import {FaEnvelope, FaCheckCircle, FaLink} from 'react-icons/fa'
const CreateAdmin = () => {
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("Member");
  const [link, setLink] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    //random link....
    const randomLink = Math.random().toString(36).substr(2, 8);
    setLink(`https://example.com/invite/${randomLink}`);
  }, []);

  useEffect(() => {
    let timeout;
    if (error || success) {
      timeout = setTimeout(() => {
        setError("");
        setSuccess("");
      }, 3000);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [error, success]);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleRoleChange = (e) => {
    setRole(e.target.value);
  };

  const handleInvite = async (e) => {
    e.preventDefault();
    //email validation
    if (!email.match(/^[^@]+@[^@]+\.[^@]+$/)) {
      setError("Please enter a valid email address");
      return;
    }

    try {
      //assuming there's an api to check existing email
      const response = await fetch("/api/checkEmail", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email }),
      });
      const data = await response.json();
      if (data.exists) {
        setError(`${email} is already on your team!`);
        return;
      }
    } catch (error) {
      console.error("Error checking email:", error);
      setError("Error checking email, please try again.");
      return;
    }

    try {
      //assuming there's an api to send invite
      await fetch("/api/sendInvite", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, role }),
      });
      setSuccess(`Invite sent to ${email}!`);
      setEmail("");
    } catch (error) {
      console.error("Error sending invite:", error);
      setError("Error sending invite, please try again.");
    }
  };

  const handleCopyLink = () => {
    navigator.clipboard.writeText(link);
    setCopied(true);
    setTimeout(() => setCopied(false), 3000);
  };

  return (
    <div className="invite">
      <div className="inv-form" style={{ display: success ? 'none' : 'block' }}>
        <h1>Invite people to your team</h1>
        <div className="invite-section">
          <h2>Invite with Email</h2>
          <form onSubmit={handleInvite}>
            <input
              type="email"
              value={email}
              onChange={handleEmailChange}
              placeholder="Enter an email address"
            />
            <select value={role} onChange={handleRoleChange}>
              <option value="Member">Member</option>
              <option value="Admin">Admin</option>
              <option value="Owner">Owner</option>
            </select>
            <button type="submit">
            <FaEnvelope/> <span> Invite </span>
            </button>
          </form>
          {error && <p className="error">{error}</p>}
        </div>
        <div className="invite-section">
          <h2>Invite with Link</h2>
          <form>
            <input type="text" value={link} readOnly />
            <button className={copied ? "copy copied" : "copy"} onClick={handleCopyLink}>
              {copied ? (
                <><FaCheckCircle/> <span>Copied</span> </>
              ) : (
                <> <FaLink/> <span> Copy Link</span></>
              )}
            </button>
          </form>
          <p>Anyone with access to this link can join your team.</p>
        </div>
      </div>

      {success && (
        <div className="success-message">
          <FaCheckCircle className="success-icon"/>
          Invite sent!
        </div>
      )}
    </div>
  );
};

export default CreateAdmin;
