import {
  Sidebar,
  SidebarHeader,
  ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
} from "react-pro-sidebar";
import Jekinraalogo from "../assets/logo/jlogo.png";
import sideBarElems from "./SideBarElems";
import Header from "./Header";
import { Person, PersonCircle } from "react-bootstrap-icons";
import { useNavigate } from "react-router";
import { useEffect, useId } from "react";
import { AdminState } from "../Providers/AdminProvider";

const SideBarUI = () => {
  const navigate = useNavigate();
  const id = useId();
  const { admin } = AdminState()
  return (
      <Sidebar className="bg-bgcolor border-r    border-r-gray-500 h-screen py-4">
        <header>
          <div className="flex gap-5 pl-[3.2rem] pt-2 items-center pb-10 w-full">
            <div>
              <Person />
            </div>
            <div className="font-Kumbh text-base text-[rgba(0,0,0,.7)] font-semibold">
              {admin?.name}
            </div>
          </div>
        </header>
        <Menu
          rootStyles={{
            height: "50px",
            padding: "1px 20px",
          }}
        >
          {sideBarElems.map((item) => {
            if (item.submenu) {
              return (
                <SubMenu
                  label={item.name}
                  className="font-Kumbh text-sm text-[rgba(0,0,0,.7)] font-medium"
                  icon={item.icon}
                  key={id}
                >
                  {item.submenu.map((subItem) => (
                    <MenuItem
                      onClick={() => {
                        navigate(subItem.url);
                      }}
                      id={id}
                      rootStyles={{
                        padding: "1px 20px",
                      }}
                      className="font-Kumbh text-xs text-[rgba(0,0,0,.7)] font-medium"
                    >
                      {subItem.name}
                    </MenuItem>
                  ))}
                </SubMenu>
              );
            }

            return (
              <MenuItem
                className="font-Kumbh text-[rgba(0,0,0,.7)] text-sm font-medium"
                onClick={() => navigate(item.link)}
                icon={item.icon}
              >
                {item.name}
              </MenuItem>
            );
          })}
        </Menu>
      </Sidebar>
  );
};

export default SideBarUI;
