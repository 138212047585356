import Jekinraalogo from "../../assets/logo/jlogo.png";
import applelogo from "../../assets/icons/apple-logo.png";
import googlelogo from "../../assets/icons/google-logo.png";
import Btn from "../Btn";
import Input from "../Input";
import { useEffect, useState } from "react";
import { baseUrl } from "../../webbase";
import { futureDate } from "../../hooks/useUtilities";
import { Toaster, toast } from "react-hot-toast";
import AuthBtn from "../AuthBtn";
import { useNavigate } from "react-router-dom";
import { EXPIRY_DATE, setCookieToken } from "../../utils";
import cookie from "react-cookies"


const SigninUI = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const navigate = useNavigate();
  const loginUser = () => {
    const url = baseUrl + "/adminapi/login";
    const data = {
      email: email,
      password: password,
    };

    setSubmitting(true);
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then(async (data) => {
        console.log("🚀 ~ file: SigninUI.js:38 ~ .then ~ data:", data)
        setSubmitting(false);
        const token = data.data.access_token;
        setCookieToken(token)
        navigate("/");
        toast.success(data.message + ` redirecting.....`, {
          className: "bg-primaryDark py-[10px]",
          duration: 2000,
          position: "top-center",
        });
      })
      .catch((error) => {
        console.log(error)
        setSubmitting(false);
        toast.error("❗Login error", {
          className: "bg-primaryDark py-[10px] text-whte",
          duration: 2000,
          position: "top-center",
        });
        // Additional error handling here
      });
  };

  return (
    <div className="w-full h-screen bg-gray-100 ">
      <Toaster />
      <div className="md:w-full md:h-screen  flex justify-center items-center">
        <div className="md:w-[38rem] w-full h-screen md:h-auto md:max-w-[42rem] bg-bgcolor rounded-2xl py-8 flex flex-col justify-center items-center">
          <div className="flex flex-col px-[3rem] md:px-[6.5rem] gap-8 ">
            <div className="font-semibold font-Kumbh text-xl text-center">
              Sign in
            </div>
            <div className="flex gap-5">
              <div>
                <Btn
                  title={"Sign in with Apple"}
                  onClick={() => { }}
                  type={"secondary"}
                  icon={<img src={applelogo} />}
                />
              </div>
              <div>
                <Btn
                  title={"Sign in with Google"}
                  onClick={() => { }}
                  type={"secondary"}
                  icon={<img src={googlelogo} />}
                />
              </div>
            </div>

            <div className="flex  gap-3 items-center justify-center">
              <div className=" md:w-[6rem] w-16 bg-[rgba(0,0,0,0.1)] h-[0.004rem]"></div>
              <div className="font-Kumbh text-[rgba(0,0,0,0.2)] text-sm">
                Or with Email
              </div>
              <div className=" md:w-[6rem] w-16 bg-[rgba(0,0,0,0.1)] h-[0.004rem]"></div>
            </div>
            {/* the form container */}
            <div className=" flex flex-col gap-5">
              <div>
                <Input
                  type={"email"}
                  placeholder={"Email"}
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  required={true}
                />
              </div>
              <div>
                <Input
                  type={"password"}
                  placeholder={"Password"}
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                  required={true}
                />
              </div>
              <div className="w-full flex justify-end font-Kumbh font-normal text-sm ">
                Forgot password?
              </div>
            </div>

            <div>
              <AuthBtn
                loading={submitting}
                title={"Sign in"}
                onClick={loginUser}
                type={"primary"}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SigninUI;
