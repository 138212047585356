import React from 'react'
import {
  PieChart,
  Tooltip,
  Pie,
  Cell
} from "recharts"
const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

// chart
const SalesChart = ({ data }) => {
  console.log(data)
  return (
    <div className="flex  flex-col gap-3  rounded-sm w-full">
      <h3 className=" capitalize font-medium mx-3">Orders record</h3>
      <div className='flex w-full justify-center'>
        <PieChart width={150} height={150}>
          <Tooltip />
          <Pie
            data={data}
            dataKey="value"
            outerRadius={70}
            innerRadius={40}
            label={({ name, value }) => `${name}: ${value}%`}
          >
            {
              data.map((item, idx) => (
                <Cell key={`cell-${idx}`} fill={COLORS[idx % COLORS.length]} />
              ))
            }

          </Pie>
        </PieChart>
      </div>
    </div>
  )
}

export default SalesChart