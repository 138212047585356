// this is where some utitlities are being imported
import JLogo from "../assets/logo/jlogo.png"
import JekinraaLogo from "../assets/logo/jekinraa.png"
import darkThemeIcon from "../assets/icons/darkTheme.svg"
import lightThemeIcon from "../assets/icons/lightTheme.svg"
import tableHamburgerIcon from "../assets/icons/tableHamburgerIcon.svg"
import personImg from "../assets/person.png"
export const utils = {
    logo: JLogo,
    lightThemeIcon, darkThemeIcon,
    tableHamburgerIcon,
    personImg
}
export const statusColors = {
    Approved: "text-amber-300", "In Progress": "text-indigo-600", Complete: "text-green-500", Pending: "text-blue-500"
}
export const MockOrdersData = [
    { id: '#CM9801', user: { name: 'Natali Craig', profilePicture: personImg }, project: 'Landing Page', address: 'Meadow Lane Oakland', date: 'Just now', status: 'In Progress', amount: "300.00", quantity: 20, price: "200.00" },
    { id: '#CM9802', user: { name: 'James Smith', profilePicture: personImg }, project: 'Logo Design', address: 'Main Street New York', date: '2 hours ago', status: 'Complete', amount: "300.00", quantity: 20, price: "200.00" },
    { id: '#CM9803', user: { name: 'Anna Lee', profilePicture: personImg }, project: 'Mobile App', address: 'Park Avenue London', date: 'Yesterday', status: 'Pending', amount: "300.00", quantity: 20, price: "200.00" },
    { id: '#CM9804', user: { name: 'John Doe', profilePicture: personImg }, project: 'Website Development', address: 'Boulevard Paris', date: '3 days ago', status: 'Approved', amount: "300.00", quantity: 20, price: "200.00" },
]
export const pieChartData = [
    { name: "Completed", value: 67.6, fill: "" },
    { name: "In Progress", value: 26.4, fill: "" },
    { name: "Behind", value: 6, fill: "" },
];

export const mockSellers = [
    {
        "id": 1,
        "Business Name": "Davis-Carter",
        "Name": "Scott Wood",
        "Logo": "https://placekitten.com/904/55",
        "Email": "cynthiahoward@yahoo.com",
        "Phone Number": "001-222-738-2385x9398",
        "Status": "Pending"
    },
    {
        "id": 2,
        "Business Name": "Berry Ltd",
        "Name": "Derrick Ortega",
        "Logo": "https://placeimg.com/469/74/any",
        "Email": "emilywong@yahoo.com",
        "Phone Number": "084.577.3047x44633",
        "Status": "Pending"
    },
    {
        "id": 3,
        "Business Name": "Willis PLC",
        "Name": "Phillip Burns",
        "Logo": "https://www.lorempixel.com/535/32",
        "Email": "youngcassidy@white-welch.net",
        "Phone Number": "(572)568-9031x9125",
        "Status": "Inactive"
    },
    {
        "id": 4,
        "Business Name": "Greer-Hogan",
        "Name": "Samuel Villa",
        "Logo": "https://dummyimage.com/952x296",
        "Email": "jillscott@porter.com",
        "Phone Number": "(668)029-6916x3164",
        "Status": "Inactive"
    },
    {
        "id": 5,
        "Business Name": "Kim Group",
        "Name": "Samuel Watson",
        "Logo": "https://www.lorempixel.com/610/526",
        "Email": "mbennett@alvarez.biz",
        "Phone Number": "001-899-915-1141x04294",
        "Status": "Inactive"
    },
    {
        "id": 6,
        "Business Name": "Serrano LLC",
        "Name": "Shelly Farrell",
        "Logo": "https://www.lorempixel.com/420/19",
        "Email": "taylor75@yahoo.com",
        "Phone Number": "(215)208-0813x07307",
        "Status": "Suspended"
    },
    {
        "id": 7,
        "Business Name": "Allison, Lane and Baxter",
        "Name": "Richard Johnston",
        "Logo": "https://placeimg.com/245/194/any",
        "Email": "natalie17@hotmail.com",
        "Phone Number": "2032859573",
        "Status": "Suspended"
    },
    {
        "id": 8,
        "Business Name": "Payne-Payne",
        "Name": "Juan Espinoza",
        "Logo": "https://www.lorempixel.com/628/15",
        "Email": "ocampos@gmail.com",
        "Phone Number": "9756820286",
        "Status": "Inactive"
    },
    {
        "id": 9,
        "Business Name": "Ayers Inc",
        "Name": "Chad Collier",
        "Logo": "https://placekitten.com/735/67",
        "Email": "wallacecatherine@davis.info",
        "Phone Number": "(277)322-5131x3362",
        "Status": "Inactive"
    },
    {
        "id": 10,
        "Business Name": "Douglas-Smith",
        "Name": "Brandon Buck",
        "Logo": "https://placeimg.com/897/149/any",
        "Email": "patricia95@hotmail.com",
        "Phone Number": "+1-694-461-5072x624",
        "Status": "Inactive"
    }
]

export const mockShoppers = [
    {
        "id": 424,
        "Name": "Michael Wilson",
        "Email": "padillamichael@valdez-rodriguez.com",
        "Phone Number": "852.593.8111",
        "Last Login": "2023-07-18",
        "Status": "Active"
    },
    {
        "id": 996,
        "Name": "Laura Snyder",
        "Email": "jesus86@martinez-smith.com",
        "Phone Number": "(473)722-2915x95047",
        "Last Login": "2023-03-21",
        "Status": "Active"
    },
    {
        "id": 544,
        "Name": "Justin Woods",
        "Email": "antoniocrane@brown-bartlett.org",
        "Phone Number": "+1-383-016-3501x997",
        "Last Login": "2023-11-21",
        "Status": "Active"
    },
    {
        "id": 612,
        "Name": "William Ramirez",
        "Email": "rhonda21@fields.com",
        "Phone Number": "(291)225-9913",
        "Last Login": "2023-07-19",
        "Status": "Inactive"
    },
    {
        "id": 162,
        "Name": "Whitney Larson",
        "Email": "suedavies@carroll.com",
        "Phone Number": "335-861-8121x384",
        "Last Login": "2023-12-21",
        "Status": "Active"
    },
    {
        "id": 426,
        "Name": "April Buchanan",
        "Email": "lauriereed@ramirez.com",
        "Phone Number": "235-364-2363x2910",
        "Last Login": "2023-02-26",
        "Status": "Active"
    },
    {
        "id": 602,
        "Name": "Jennifer Meyers",
        "Email": "moorebarbara@hill.com",
        "Phone Number": "+1-432-602-9845x3266",
        "Last Login": "2023-04-24",
        "Status": "Active"
    },
    {
        "id": 141,
        "Name": "Kelly Davidson",
        "Email": "robynrush@whitney-adams.biz",
        "Phone Number": "056.825.0808x75408",
        "Last Login": "2023-04-27",
        "Status": "Active"
    },
    {
        "id": 155,
        "Name": "Mary Riley",
        "Email": "fred04@hotmail.com",
        "Phone Number": "(535)150-7503",
        "Last Login": "2023-07-22",
        "Status": "Inactive"
    },
    {
        "id": 916,
        "Name": "Shelby Daniels",
        "Email": "rogerskimberly@rogers.info",
        "Phone Number": "869.056.9367",
        "Last Login": "2023-06-10",
        "Status": "Active"
    }
]


export const barChartData = [{ "Month": "January", "Total Amount": 7598.22 }, { "Month": "February", "Total Amount": 7510.46 }, { "Month": "March", "Total Amount": 6761.56 }, { "Month": "April", "Total Amount": 8844.13 }, { "Month": "May", "Total Amount": 7886.72 }, { "Month": "June", "Total Amount": 7659.42 }, { "Month": "July", "Total Amount": 5893.79 }, { "Month": "August", "Total Amount": 5540.69 }, { "Month": "September", "Total Amount": 7152.89 }, { "Month": "October", "Total Amount": 8196.44 }, { "Month": "November", "Total Amount": 6570.46 }, { "Month": "December", "Total Amount": 6843.95 }]