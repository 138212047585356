import { BrowserRouter, Route, Routes, createBrowserRouter, createRoutesFromElements } from "react-router-dom";
import Signin from "./pages/signin";
import Admin from "./Admin";
import Transactions from "./pages/Transactions";
import AllSellers from "./pages/Sellers/AllSeller";
import ActiveSellers from "./pages/Sellers/ActiveSellers";
import PendingSellers from "./pages/Sellers/PendingSellers";
import AllProducts from "./pages/Products/AllProducts";
import PendingProducts from "./pages/Products/PendingProducts";
import Shoppers from "./pages/Shoppers/Shopper";
import Dashboard from "./pages/Dashboard";
import CreateAdmin from "./pages/createAdmin";
import SingleProduct from "./pages/Products/SingleProduct";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" >
      {/* the login route */}
      <Route path="/login" element={<Signin />} />
      {/* the admin  */}
      <Route path="/" element={<Admin />}>
        {/* admin  home route*/}
        <Route path="/" element={<Dashboard />} />
        {/* transactions route */}
        <Route path="transactions" element={<Transactions />} />
        <Route path="all-sellers" element={<AllSellers />} />
        <Route path="create-admin" element={<CreateAdmin />} />
        <Route path="active-sellers" element={<ActiveSellers />} />
        <Route path="pending-sellers" element={<PendingSellers />} />
        <Route path="shoppers" element={<Shoppers />} />
        <Route path="all-products" element={<AllProducts />} />
        <Route path="pending-products" element={<PendingProducts />} />
        <Route path='product/:productId' element={<SingleProduct />} />
      </Route>
    </Route>
  )
)
export default router;
