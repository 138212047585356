import React, { useState } from "react";
import { MockOrdersData, mockSellers, utils } from "../../components/utils";
import TableComponent from "../../components/TableComponent";
import { Column, DataRow } from "../../hooks/useUtilities";
import axios from "axios";
import { baseUrl } from "../../webbase";
import cookie from "react-cookies"


const ActiveSellers = () => {
  const [sellersList, setSellerList] = useState([]);
  const columns/* : Column[] */ = [
    { header: "ID", accessor: "id" },
    { header: "Logo", accessor: "logo" },
    { header: "Business Name", accessor: "business_name" },
    { header: "Name", accessor: "name" },
    { header: "Email", accessor: "email" },
    { header: "Phone Number", accessor: "phone" },
    { header: "Status", accessor: "user_type", editable: true },
    { header: "Request", accessor: "request" },
    { header: "Access", accessor: "access" },
  ];

  React.useEffect(() => {
    const token = cookie.load("Jekinraa-admin");
    axios
      .get(baseUrl + "/adminapi/getAllVendors", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(({ data }) => {
        const ActiveSellers = data.data[0].filter(products => products.user_type === "2")
        setSellerList(ActiveSellers)
        console.log(data.data[0]);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);
  return (
    <div className="p-2 bg-white">
      <h3>All Sellers</h3>
      {/* the table preceeding header */}
      <TableComponent
        columns={columns}
        data={sellersList}
        onRowSelect={(selectedRows) => {

        }}
      />
    </div>
  );
};

export default ActiveSellers;
