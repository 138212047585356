const Btn = ({ type, icon, title, onClick, isLoader, isLoading }) => {
  const btnstyle =
    type == "primary"
      ? "px-3 py-4 bg-primaryLight w-full py-[0.2rem] rounded-lg text-white hover:bg-transparent hover:text-primaryLight border border-primaryLight"
      : " px-3 py-4 border-2 border-gray-100 py-[0.2rem] rounded-lg";
  const titlestyle =
    type == "primary"
      ? "font-Kumbh font-medium  text-base py-4  "
      : "font-Kumbh font-medium text-[rgba(0,0,0.85)] text-sm";

  return (
    <button onClick={onClick} className={btnstyle}>
      <div className="flex items-center gap-3 justify-center">
        {icon ? <div className="w-5 h-auto">{icon}</div> : null}
        <div className={titlestyle}>{title}</div>
      </div>
    </button>
  );
};

export default Btn;
