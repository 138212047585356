import SigninUI from "../components/Signin/SigninUI"
import NavBar from "../layouts/NavBar"

const Signin = () => {
   return (
      <div className="w-full h-screeen">
         <header>
            <NavBar/>
         </header>

         <main>
            <SigninUI/>
         </main>
      </div>
   )
}

export default Signin