import React, {useEffect, useRef, useState} from "react";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

// chart
const TransactionsChart = ({data}: any) => {
  const parentDivRef = useRef<HTMLDivElement>(null);
  const [divSize, setDivSize] = useState({width: 0, height: 0});

  useEffect(() => {
    if (parentDivRef.current) {
      setDivSize({
        width: parentDivRef.current?.offsetWidth,
        height: parentDivRef.current?.offsetHeight,
      });
    }
  }, [parentDivRef]);

  return (
    <div
      className="flex  flex-col gap-3  rounded-sm w-full h-72"
      ref={parentDivRef}
    >
      <h3 className=" capitalize font-medium mx-3 mb-4">
        Transactions Overview (NGN)
      </h3>
      <div className="flex w-full justify-center">
        <BarChart
          height={divSize.height - 30}
          width={divSize.width}
          data={data}
        >
          <XAxis dataKey="Month" />
          <YAxis />
          <Tooltip />
          <Bar
            dataKey="Total Amount"
            fill="#95A4FC"
            shape={
              <svg
                width="343"
                height="990"
                viewBox="0 0 343 990"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{}}
              >
                <rect
                  x="15"
                  y="10"
                  width="40"
                  height="100"
                  rx="14"
                  fill="#95A4FC"
                />
              </svg>
            }
          />
        </BarChart>
      </div>
    </div>
  );
};

export default TransactionsChart;
