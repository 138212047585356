import React from 'react'
import TableColumn from './TableColumn'
import TableBody from './TableBody'
import TableHeader from './TableHeader'

const OrdersTable = ({orders}) => {
  return (
    <table className='table-fixed table w-full'>
        <TableHeader/>
        <TableBody data={orders}/>
    </table>
  )
}

export default OrdersTable