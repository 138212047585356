import React, { useState } from "react";
import { toast, ToastContainer } from 'react-toastify';
import { MockOrdersData, mockSellers, utils } from "../../components/utils";
import TableComponent from "../../components/TableComponent";
import { Column, DataRow } from "../../hooks/useUtilities";
import axios from "axios";
import { baseUrl } from "../../webbase";
import cookie from "react-cookies"


const AllSellers = () => {
  const [sellersList, setSellerList] = useState([]);
  const [fetching, setFetching] = React.useState(false)
  const columns/* : Column[] */ = [
    { header: "ID", accessor: "id" },
    { header: "Logo", accessor: "logo" },
    { header: "Business Name", accessor: "business_name" },
    { header: "Name", accessor: "name" },
    { header: "Email", accessor: "email" },
    { header: "Phone Number", accessor: "phone" },
    { header: "Status", accessor: "user_type", editable: true },
    { header: "Request", accessor: "request" },
    { header: "Access", accessor: "access" },
  ];

  React.useEffect(() => {
    const token = cookie.load("Jekinraa-admin");
    setFetching(true)
    axios
      .get(baseUrl + "/adminapi/getAllVendors", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(({ data }) => {
        setSellerList(data.data[0])
        console.log(data.data[0]);
        setFetching(false)
      })
      .catch((e) => {
        setFetching(false)
        console.log(e);
      });
  }, []);
  return (
    <div className="p-2 bg-white">
      <ToastContainer />
      <h3>All Sellers</h3>
      {/* the table preceeding header */}
      <TableComponent
        columns={columns}
        data={sellersList}
        onRowSelect={(selectedRows) => {

        }}
      />
    </div>
  );
};

export default AllSellers;
