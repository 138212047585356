import React, { useState } from "react";
import { MockOrdersData, mockSellers, utils } from "../../components/utils";
import { toast, ToastContainer } from 'react-toastify';
import TableComponent from "../../components/TableComponent";
import { Column, DataRow } from "../../hooks/useUtilities";
import axios from "axios";
import { baseUrl } from "../../webbase";
import cookie from "react-cookies"

const AllProducts = () => {
  const [productsList, setProductList] = useState([]);
  const columns/* : Column[] */ = [
    { header: "ID", accessor: "id" },
    { header: "image", accessor: "images[0].url" },
    { header: "Seller Name", accessor: "sellerName" },
    { header: "Name", accessor: "name" },
    { header: "description", accessor: "description" },
    { header: "price", accessor: "price" },
    { header: "Action", accessor: "product-action" },
  ];

  React.useEffect(() => {
    const token = cookie.load("Jekinraa-admin");
    axios
      .get(baseUrl + "/adminapi/getAllProducts", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(({ data }) => {
        setProductList(data.data)
        console.log(data.data)
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);
  return (
    <div className="p-2 bg-white">
      <ToastContainer />
      <h3>All Products</h3>
      {/* the table preceeding header */}
      <TableComponent
        columns={columns}
        data={productsList}
        onRowSelect={(selectedRows) => {

        }}
      />

    </div>
  );
};

export default AllProducts;
