import { Outlet } from "react-router-dom";
import Header from "./layouts/Header";
import SideBarUI from "./layouts/SideBar";
import AdminProvider from "./Providers/AdminProvider";


const Admin = () => {
  return (
    <AdminProvider>
      <div className="md:w-full h-screen bg-white sm:w-screen ">
        <div className="md:flex sm:block w-full h-screen sm:w-screen sm:fixed sm:left-0 sm:top-0 sm:z-10">
          {/* sidebar */}
          <nav>
            <SideBarUI />
          </nav>
          <div className=" blur-sm sm:w-screen sm:h-screen md:w-0 fixed left-0 top-0 " />

          {/* body */}
          <div className="md:w-full sm:w-screen sm:h-screen overflow-y-scroll">
            {/* main body header */}
            <header>
              <Header />
            </header>
            {/* main body */}
            <main>
              <div className="px-7 w-full">
                {/* where all the components are being rendered according to the route path */}
                <Outlet />
              </div>
            </main>
          </div>
        </div>
      </div>
    </AdminProvider>
  );
};

export default Admin;
