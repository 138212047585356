import React, {useEffect, useState} from "react";
import cookie from "react-cookies"

import {
  MockOrdersData,
  mockSellers,
  mockShoppers,
  utils,
} from "../../components/utils";
import TableComponent from "../../components/TableComponent";
import {Column, DataRow} from "../../hooks/useUtilities";
import {baseUrl} from "../../webbase";
import axios from "axios";

const Shoppers = () => {
  const [shoppersList, setList] = useState<DataRow[]>([]);
  const [fetching, setFetching] = React.useState(false);
  

  const columns /* : Column[]  */ = [
    {header: "ID", accessor: "id"},
    {header: "Name", accessor: "name"},
    {header: "Email", accessor: "email"},
    {header: "Phone Number", accessor: "phone"},
    {header: "Status", accessor: "status", editable: true},
  ];
  useEffect(() => {
    (() => {
      const token = cookie.load("Jekinraa-admin");
      setFetching(true);
      axios
        .get(baseUrl + "/adminapi/getAllVendors", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(({data}) => {
          setFetching(false);
          setList(data.data[0]);
          console.log(data.data[0]);
        })
        .catch((e) => {
          setFetching(false);
          console.log(e);
        });
        setFetching(false)
    })();
  });
  return (
    <div className="p-2 bg-white">
      <h3>All Shoppers</h3>
      {/* the table preceeding header */}
      <TableComponent
        columns={columns}
        data={shoppersList}
        onRowSelect={(selectedRows) => {}}
        fetching={fetching}
      />
    </div>
  );
};

export default Shoppers;
