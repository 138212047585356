import React from 'react'
import { MockOrdersData, statusColors } from './utils'
import {GoDotFill} from "react-icons/go"
const TableBody = ({ data }) => {

    return (
        <tbody>
            {data.map((order, idx) => (
                <tr key={idx}>
                    <td className="px-6  whitespace-nowrap">
                        <div className="flex items-center space-x-2 py-2">


                            <input type="checkbox" className="w-[15px] h-[15px]" name="" id="" />
                            <span className='text-sm font-medium text-gray-900'>
                                {order.id}
                            </span>
                        </div>
                        {/* <div className="text-sm font-medium text-gray-900"></div> */}
                    </td>
                    {/* user details */}
                    <td className="px-6 whitespace-nowrap">
                        <div className="flex items-center">
                            <div className="flex-shrink-0 h-10 w-10 py-2">
                                <img

                                    className="h-10 w-10 rounded-full"
                                    src={order.user.profilePicture}
                                    alt={order.user.name}
                                />
                            </div>
                            <div className="ml-4">
                                <div className="text-sm font-medium text-gray-900">{order.user.name}</div>
                                <div className="text-sm text-gray-500">{order.user.email}</div>
                            </div>
                        </div>
                    </td>
                    <td className="px-6  whitespace-nowrap">
                        <div className="text-sm text-gray-900">{order.project}</div>
                    </td>
                    <td className="px-6  whitespace-nowrap">
                        <div className="text-sm text-gray-900">{order.address}</div>
                    </td>
                    <td className="px-6  whitespace-nowrap">
                        <div className="text-sm text-gray-900">{order.quantity}</div>
                    </td>
                    <td className="px-6  whitespace-nowrap">
                        <div className="text-sm text-gray-900"><span className='mr-1'>&#x20A6;</span>{order.amount.toString()}</div>
                    </td>
                    <td className="px-6 py-2  whitespace-nowrap">
                        <div className="text-sm text-gray-900"><span className='mr-1'>&#x20A6;</span>{order.price}</div>
                    </td>
                    <td className={`px-6  py-2 whitespace-nowrap flex items-center   ${statusColors[order.status]}`}>
                        <span  >

                        <GoDotFill size={10}/>
                        </span>
                        <span
                            className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${statusColors[order.status]}`}
                        >
                            {order.status}
                        </span>
                    </td>
                </tr>
            ))}
        </tbody>
    )
}

export default TableBody