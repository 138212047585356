
import { Bag, Bank, BarChart, Box, BoxArrowDownRight, Briefcase, CashStack, FileCode, House, Person, Wallet2 } from "react-bootstrap-icons"



const sideBarElems = [
   {
      name: "Dashboard",
      icon: <House />,
      link: "/"
   },

   {
      name: "Transactions",
      icon: <BarChart />,
      link: "transactions"
   },
   {
      name: "Sellers",
      icon: <Briefcase />,
      submenu: [
         {
            name: "All Sellers",
            url: "all-sellers"
         },
         {
            name: "Active Sellers",
            url: "active-sellers"
         },
         {
            name: "Pending Sellers",
            url: "pending-sellers"
         }
      ]
   },
   {
      name: "Shoppers",
      icon: <Bag />,
      link: "shoppers"
   },
   {
      name: "Products",
      icon: <Box />,
      submenu: [
         {
            name: "All Products",
            url: "all-products"
         },
         {
            name: "Pending Products",
            url: "pending-products"
         }
      ]
   },
   {
      name: "Payouts",
      icon: <Wallet2 />
   },
   {
      name: "Refunds",
      icon: <Bank />
   },
   {
      name: "Manage Fees",
      icon: <CashStack />
   },
   {
      name: "Manage Users",
      icon: <Person />,
      submenu: [
         {
            name: "View Admin",
            url: "/"
         },
         {
            name: "Invite Users",
            url: "/create-admin"
         }
      ]
   },
   // {
   //    name:"API documentation",
   //    icon: <FileCode/>
   // },
   {
      name: "Sign Out",
      icon: <BoxArrowDownRight />,
      link: "/login"
   }

]

const sellers = [
   "All Sellers",
   "Active Sellers",
   "Pending Sellers"
]

const product = [
   "All Products",
   "Pending Products"
]

const manageUsers = [
   "Invite Users",
   "View Admin"
]

export default sideBarElems