import { FiEye, FiEyeOff } from "react-icons/fi";
import React from "react";
const Input = ({ type, placeholder, required, value, onChange, ...props }) => {
  const [show, setShow] = React.useState(false);
  const [focussed, setFocussed] = React.useState(false);
  return (
    <>
      {type === "password" ? (
        <div
          className={
            focussed
              ? `flex items-center  w-full  font-Kumbh border-2  rounded-lg placeholder-gray-300 border-[#9D00AF] outline-1 `
              : `flex items-center  w-full  font-Kumbh border-2 border-gray-100 rounded-lg placeholder-gray-300  outline-1 `
          }
        >
          <input
            type={show ? "text" : "password"}
            placeholder={placeholder}
            required
            value={value}
            onBlur={() => {
              setFocussed(false);
            }}
            onFocus={() => {
              setFocussed(true);
            }}
            onChange={onChange}
            className="px-4 w-full flex-1 font-Kumbh  rounded-lg placeholder-gray-300  py-4 h-full"
            {...props}
          />
          <div onClick={() => setShow(!show)} className="p-4">
            {show ? <FiEye /> : <FiEyeOff />}
          </div>
        </div>
      ) : (
        <div>
          <input
            type={type}
            placeholder={placeholder}
            required
            value={value}
            onChange={onChange}
            className="px-4 w-full font-Kumbh py-1 border-2 border-gray-100 rounded-lg placeholder-gray-300 focus:outline-[#9D00AF] outline-1 py-4"
            {...props}
          />
        </div>
      )}
    </>
  );
};

export default Input;
