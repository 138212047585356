import { ScaleLoader } from "react-spinners";

const AuthBtn = ({ type, loading, title, onClick, isLoader, isLoading }) => {
  const btnstyle =
    type == "primary"
      ? `px-3 py-4 bg-${isLoading || loading ? "primaryLight" : "primary"} w-full py-[0.2rem] rounded-lg text-white hover:bg-transparent hover:text-primaryLight border border-primaryLight`
      : " px-3 py-4 border-2 border-gray-100 py-[0.2rem] rounded-lg";
  const titlestyle =
    type == "primary"
      ? "font-Kumbh font-medium  text-black text-base py-4  "
      : "font-Kumbh font-medium text-black text-sm";

  return (
    <button onClick={onClick} className={btnstyle}>
      <div className="flex items-center gap-3 justify-center">
        <div className={titlestyle}>{title}</div>
        {loading && (
          <div className="ml-2">
            <ScaleLoader color="#9D00AF" height={20} />
          </div>
        )}
      </div>
    </button>
  );
};

export default AuthBtn;
