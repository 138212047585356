import React, { useState } from "react";
import { MockOrdersData, mockSellers, utils } from "../../components/utils";
import TableComponent from "../../components/TableComponent";
import { Column, DataRow } from "../../hooks/useUtilities";
import axios from "axios";
import { baseUrl } from "../../webbase";
import cookie from "react-cookies"

const PendingProducts = () => {
  const [productsList, setProductList] = useState([]);
  const columns/* : Column[] */ = [
    { header: "ID", accessor: "id" },
    { header: "image", accessor: "images[0].url" },
    { header: "Seller Name", accessor: "sellerName" },
    { header: "Name", accessor: "name" },
    { header: "description", accessor: "description" },
    { header: "price", accessor: "price" },
    { header: "quantity", accessor: "quantity" },
  ];

  React.useEffect(() => {
      const token = cookie.load("Jekinraa-admin");
      axios
        .get(baseUrl + "/adminapi/getAllProducts", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(({data}) => {
            const PendingProducts = data.data.filter( products => products.status === "0")
          setProductList(PendingProducts)          
          console.log(data.data)          
          console.log("pending", PendingProducts)          
        })
        .catch((e) => {
          console.log(e);
        });
  }, []);
  return (
    <div className="p-2 bg-white">
      <h3>All Products</h3>
      {/* the table preceeding header */}
      <TableComponent
        columns={columns}
        data={productsList}
        onRowSelect={(selectedRows) => {

        }}
      />
    </div>
  );
};

export default PendingProducts;
