import React from "react";

import { MockOrdersData, utils } from "../components/utils";
import OrdersTable from "../components/OrdersTable";
import axios from "axios";
import { AdminState } from "../Providers/AdminProvider";
import { baseUrl } from "../webbase";

const Transactions = () => {
  const [loading, setLoading] = React.useState(false);
  const { token } = AdminState();
  const fetchTransactions = () => {
    setLoading(true);

    fetch(`${baseUrl}/adminapi/getAllTransactions`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      })
      .then(res=>res.json())
      .then((data) => console.log(data.data))
      .catch((e) => console.log(e));
  };
  React.useEffect(() => {
    fetchTransactions();
  }, []);
  return (
    <div className="p-2 bg-white">
      <h3>Transactions</h3>
      {/* the table preceeding header */}
      <OrdersTable orders={MockOrdersData} />
    </div>
  );
};

export default Transactions;
