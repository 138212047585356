import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import 'react-toastify/dist/ReactToastify.css';
import { BrowserRouter, RouterProvider } from "react-router-dom";
import router from "./Routers";
import { CookiesProvider } from "react-cookie";
import { futureDate } from "./hooks/useUtilities";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <CookiesProvider
      defaultSetOptions={{
        path: "/",
        expires: futureDate,
        maxAge: 1000,
        secure: true,
        httpOnly: true,
        // sameSite: "strict",

      }}
    >
      <RouterProvider router={router} />
    </CookiesProvider>
  </React.StrictMode>
);
