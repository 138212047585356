import ProjectsUI from "../components/Dashboard/ProjectsUI"

const Dashboard = () => {
   return (
      <div
         className="w-full h-screen"
      >
         <ProjectsUI />
      </div>
   )
}
export default Dashboard