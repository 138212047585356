import { List } from "react-bootstrap-icons";
import { utils } from "../components/utils";
import { LuBell } from "react-icons/lu";
import React from "react";
import SearchBox from "../components/SearchBox";
// this is the admin header for  the dashboard
const Header = () => {
  return (
    <div className="bg-green flex items-center w-full border-b px-4 py-5 border-b-gray-200 ">
      <div>
        <List className="w-6 h-auto" />
      </div>
      <Right />
    </div>
  );
};

export default Header;

// the right handside part of the table
const Right = () => {
  const [isLight, setIsLight] = React.useState(true);

  return (
    <div className="flex flex-row gap-2 items-center ml-auto">
      {/* switch theme */}
      <div
        className="theme-icon cursor-pointer"
        onClick={() => setIsLight(!isLight)}
      >
        {isLight ? (
          <img className="w-[20px] h-[20px]" src={utils.darkThemeIcon} alt="" />
        ) : (
          <img
            className="w-[20px] h-[20px]"
            src={utils.lightThemeIcon}
            alt=""
          />
        )}
      </div>
      {/* notification button */}
      <div className="theme-icon cursor-pointer">
        <LuBell />
      </div>
    </div>
  );
};
