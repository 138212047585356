import React from 'react'
import { FiSearch } from "react-icons/fi"
// this is the admin search box
const SearchBox = ({height}) => {
    return (
        <div className={`flex items-center align-middle gap-2 px-3 py-2 h-[${height}] rounded-[20px] bg-gray-200 border-none md:w-[250px] `} >
            <span >
                <FiSearch color='rgba(0, 0, 0, 0.3)' />
            </span>
            <input type="search" style={{ all: "unset" }} className='cursor-text px-5 py-[10px] h-full w-[70%] 
       ' placeholder='search' />

        </div>
    )
}

export default SearchBox